<template>
  <page-container
      ref="pageContainer"
      :scroll-top.sync="scrollTop"
  >
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <!-- 广告 -->
      <div class="home-img-swiper-wrap">
        <div class="swiper-container">
          <van-swipe
              style="height: 100%"
              duration="1000"
              autoplay="5000"
          >
            <van-swipe-item
                v-for="({ imageUrl, href }, index) of imageList"
                :key="index"
                @click="handleAdClick(href)"
            >
              <van-image
                  :src="imageUrl"
                  fill="cover"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

<!--      <p class="home-item-title">精品课程</p>-->
<!--      &lt;!&ndash; 课程 &ndash;&gt;-->
<!--      <div class="home-course-container">-->
<!--        <div-->
<!--            v-for="{ courseId, courseName, classification } in courseList"-->
<!--            :key="courseId"-->
<!--            class="course-item-container"-->
<!--        >-->
<!--          <div class="course-item">-->
<!--            <div class="course-info-container">-->
<!--              <div class="course-icon-item"/>-->
<!--              <div class="course-info-item">-->
<!--                <div class="course-name">{{ courseName }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="btn-container">-->
<!--              <div class="sub-text">{{ classification }}</div>-->
<!--              <van-button-->
<!--                  type="primary" size="mini"-->
<!--                  icon="arrow" icon-position="right"-->
<!--                  round plain-->
<!--              >立即学习-->
<!--              </van-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <p class="home-item-title mission-title-item">
        进行中的任务
        <span @click="handleViewMoreMission">查看更多<van-icon name="arrow"/></span>
      </p>
      <!-- 任务 -->
      <div class="mission-list-container">
        <list-item
            v-for="mission of missionList"
            :key="mission.taskId"
            :value="mission"
            :props="{
              title: 'title',
              imgSrc: 'coverImg',
              tagName: 'taskType',
            }"
            @click="handleMissionClick(mission)"
        >
          <template #tag-name="{ data }">{{ classificationMap[data] }}</template>
          <template #bottom="{ data }">
            <div class="custom-mission-info">
<!--              <van-icon v-if="data.studyCount" name="fire"/>-->
<!--              <span>{{ data.studyCount }}</span>-->
<!--              <div v-if="data.status" class="mission-status">{{ MISSION_STATUS_MAP[data.status] }}</div>-->
              任务进度: {{ data.finish || 0 }} / {{ data.forwardTime }}
            </div>
          </template>
        </list-item>
      </div>

    </van-pull-refresh>
  </page-container>
</template>

<script>
import { mapState } from 'vuex';
import { getBannerTestApi, getCourseTestApi } from '@/test/home';
import { getAdCarouselList, getDictType } from '@/api/system';
import { getIncompleteMissionList, getTaskType } from '@/api/mission';
import ListItem from '@/components/listItem';
import { MISSION_STATUS, MISSION_STATUS_MAP } from '@/views/mission/const';

export default {
  name: 'homeHome',
  components: { ListItem },
  data() {
    return {
      scrollTop: 0,
      refreshing: false,

      classificationMap: {},

      imageList: [],
      courseList: [],
      missionList: [],
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeHome.refreshing,
    }),
    MISSION_STATUS_MAP() {
      return MISSION_STATUS_MAP;
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeHome/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  methods: {
    handleAdClick(href) {
      if (href)
        window.location.href = href;
      // href && window.open(href);
    },
    onRefresh() {
      // 字典
      getTaskType().then(res => {
        let dicList = Array.isArray(res) ? res : [];
        this.classificationMap = [{}, ...dicList].reduce((map, { value, label }) => {
          map[value] = label;
          return map;
        });
      });

      let pmsBanner = getAdCarouselList().then(res => {
        this.imageList = Array.isArray(res) ? res : [];
      });

      let pmsCourse = getCourseTestApi().then(res => {
        this.courseList = Array.isArray(res) ? res : [];
      });

      let pmsMission = getIncompleteMissionList().then(res => {
        this.missionList = Array.isArray(res) ? res : [];
      });

      Promise.all([pmsBanner, pmsCourse, pmsMission]).then(() => {
        this.refreshing = false;
      });
    },
    handleMissionClick(mission) {
      let { taskId, userTaskId, taskType, title } = mission || {};
      // let classTitle = `【${this.classificationMap[taskType]}】${title}`;
      let classTitle = this.classificationMap[taskType];
      this.$router.push({
        path: '/missionDetailList',
        query: { taskId, userTaskId, title: classTitle },
      });
      this.$store.commit('missionDetailList/setRefreshing', true);
    },
    handleViewMoreMission() {
      this.$router.replace({
        path: '/home/mission',
        query: {
          status: MISSION_STATUS.JIN_XING_ZHONG,
        },
      });

      this.$store.commit('homeMission/setRefreshing', true);
    },
  },
};
</script>

<style lang="less" scoped>
.home-img-swiper-wrap {
  position: relative;
  width: 100%;
  padding-top: 54%;
  background-color: #fff;

  @swipe-padding: 15px;

  .swiper-container {
    position: absolute;
    z-index: 1;
    top: @swipe-padding;
    bottom: @swipe-padding;
    left: 0;
    width: 100%;

    .van-swipe-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 0 @swipe-padding;

      .van-image {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    /deep/ .van-swipe__indicator--active {
      bottom: 7px;
      border-radius: 4px;
      transform: scale(1.2);
      transition: all .2s;
    }
  }
}

.home-item-title {
  display: flex;
  align-items: center;
  margin: 13px 0 0 0;
  padding: 0 16px;
  height: 48px;
  font-size: 17px;
  font-weight: bolder;
  color: #333;
  background-color: #fff;

  &.mission-title-item {
    justify-content: space-between;
    padding-right: 0;

    & > span {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 100%;
      font-size: 14px;
      font-weight: normal;
      color: #8d8d8d;

      &:active {
        background-color: #f1f1f1;
        border-radius: 20px;
      }

      .van-icon {
        margin-left: .3em;
      }
    }
  }
}

.home-course-container {
  padding: 0 16px;
  font-size: 0;
  background-color: #fff;

  .course-item-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0 0 16px 0;
    width: 50%;
    font-size: 14px;

    &:nth-of-type(odd) {
      padding: 0 8px 0 0;
    }

    &:nth-of-type(even) {
      padding: 0 0 0 8px;
    }

    .course-item {
      padding: 11px 8px 8px 8px;
      border-radius: 4px;
      background-color: #f7f9fb;

      @course-icon-size: 38px;

      .course-info-container {
        display: flex;

        .course-icon-item {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          width: @course-icon-size;
        }

        .course-info-item {
          flex: 1;
          padding: 0 0 0 10px;

          .course-name {
            display: -webkit-box;
            height: 40px;
            line-height: 20px;
            letter-spacing: 0;
            font-size: 14px;
            color: #333;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .btn-container {
        display: flex;
        margin: 13px 0 0 0;
        padding: 0 0 4px 0;

        .sub-text {
          width: 48%;
          line-height: 22px;
          font-size: 12px;
          color: #8b919a;
        }

        .van-button {
          padding: 0;
          width: 52%;
          background-color: #0000;

          .van-icon {
            margin: 0;
          }
        }
      }
    }
  }

  //test color
  .course-item-container:nth-child(1) {
    .course-icon-item {
      background: url("../../assets/home/course-top1.png") center no-repeat;
      background-size: contain;
    }
  }

  .course-item-container:nth-child(2) {
    .course-icon-item {
      background: url("../../assets/home/course-top2.png") center no-repeat;
      background-size: contain;
    }
  }

  .course-item-container:nth-child(3) {
    .course-icon-item {
      background: url("../../assets/home/course-top3.png") center no-repeat;
      background-size: contain;
    }
  }

  .course-item-container:nth-child(4) {
    .course-icon-item {
      background: url("../../assets/home/course-top4.png") center no-repeat;
      background-size: contain;
    }
  }
}

.mission-list-container {
  margin: 0 0 16px 0;
  padding: 0 16px;
  background-color: #fff;

  .custom-mission-info {
    position: relative;
    z-index: 1;
    padding: 11px 0 0 0;
    line-height: 12px;

    .van-icon.van-icon-fire {
      font-size: 12px;
      color: #e25951;
    }

    & > span {
      margin-left: 7px;
      font-size: 12px;
      color: #999;
    }

    .mission-status {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      width: 60px;
      height: 24px;
      font-size: 14px;
      color: #fff;
      background-color: @primary-color;
      border-radius: 12px;
    }
  }
}
</style>

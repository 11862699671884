var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{ref:"pageContainer",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-pull-refresh',{staticStyle:{"position":"relative","z-index":"1"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('div',{staticClass:"home-img-swiper-wrap"},[_c('div',{staticClass:"swiper-container"},[_c('van-swipe',{staticStyle:{"height":"100%"},attrs:{"duration":"1000","autoplay":"5000"}},_vm._l((_vm.imageList),function(ref,index){
var imageUrl = ref.imageUrl;
var href = ref.href;
return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.handleAdClick(href)}}},[_c('van-image',{attrs:{"src":imageUrl,"fill":"cover"}})],1)}),1)],1)]),_c('p',{staticClass:"home-item-title mission-title-item"},[_vm._v(" 进行中的任务 "),_c('span',{on:{"click":_vm.handleViewMoreMission}},[_vm._v("查看更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"mission-list-container"},_vm._l((_vm.missionList),function(mission){return _c('list-item',{key:mission.taskId,attrs:{"value":mission,"props":{
              title: 'title',
              imgSrc: 'coverImg',
              tagName: 'taskType',
            }},on:{"click":function($event){return _vm.handleMissionClick(mission)}},scopedSlots:_vm._u([{key:"tag-name",fn:function(ref){
            var data = ref.data;
return [_vm._v(_vm._s(_vm.classificationMap[data]))]}},{key:"bottom",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"custom-mission-info"},[_vm._v(" 任务进度: "+_vm._s(data.finish || 0)+" / "+_vm._s(data.forwardTime)+" ")])]}}],null,true)})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }